<template>
  <div class="file-list">
    <div>
      <b-form-group
        label="Search:"
        label-for="filter"
        label-cols-md="6"
        label-align-md="right"
        label-size="sm"
      >
        <b-input id="filter" v-model="filter" type="search" size="sm" />
      </b-form-group>

      <b-table
        :items="files"
        :fields="fields"
        :filter="filter"
        :filter-included-fields="filterOnColumns"
        :busy="showBusyState"
        primary-key="id"
        stacked="md"
        striped
        sort-icon-left
        id="file-table"
        show-empty
        :per-page="pageSize"
        :current-page="page"
        @filtered="onFiltered"
      >
        <template #empty>
          There are no files stored. To store files click on the
          <strong>Add File</strong> button.
        </template>
        <template #emptyfiltered>
          There are no files matching your search
        </template>
        <template #table-busy>
          <div class="text-center text-default">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(name)="row">
          <a :href="getUrl(row.item.id)" :download="row.item.name">
            {{ row.value }}
          </a>
        </template>
        <template #cell(expiryDate)="row">
          <span :class="row.value.class">{{ row.value.text }}</span>
        </template>
        <template #cell(actions)="row">
          <clickable-icon
            @click="viewFileDetails(row.item)"
            icon="file-earmark"
            sr-label="View File Meta Data"
          />
          <clickable-icon
            v-if="canShow"
            @click="showEditFileModal(row.item)"
            icon="pencil"
            sr-label="Edit File"
            class="pl-1"
          />
          <clickable-icon
            v-if="canShow"
            @click="showDeleteFileModal(row.item)"
            icon="x-circle"
            variant="danger"
            sr-label="Remove File"
            class="pl-1"
          />
        </template>
      </b-table>

      <Pagination
        :number-of-elements="pageElements"
        :total-rows="filteredFilesCount"
        :current-page="page"
        :per-page="pageSize"
        @update-current-page="updateCurrentPage"
      />
    </div>

    <div class="d-flex mt-2">
      <b-button v-if="canShow" @click="showAddFileModal()" variant="primary">
        Add File
      </b-button>
      <span class="ml-auto">
        Total Storage: {{ formattedUsedStorage }} / {{ formattedMaxStorage }}
      </span>
    </div>

    <file-upload-modal
      :org-id="orgId"
      :selected-file="selectedFile"
      :max-file-size="maxFileSize"
    >
    </file-upload-modal>
    <file-details-modal :file-id="selectedFileId" />

    <b-modal id="confirm-delete-modal" title="Delete File" size="md">
      <template #default>
        <p>
          <strong>
            Are you sure you want to delete "{{ selectedFile.name }}"?
          </strong>
        </p>
        <p>
          <em>
            The file will be permanently deleted from your file storage. You
            can't undo this action.
          </em>
        </p>
        <p>
          This will not delete the file from forms that it has already been
          added to.
        </p>
      </template>
      <template #modal-footer="{cancel}">
        <b-button variant="dark" @click="cancel()"> Cancel </b-button>
        <b-overlay
          :show="isDeleting"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
          id="delete-button-overlay"
        >
          <b-button variant="danger" @click="handleDeleteFile">
            Delete
          </b-button>
        </b-overlay>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapState, mapGetters} from "vuex";
import ClickableIcon from "@/components/interface/ClickableIcon.vue";
import moment from "moment";
import numeral from "numeral";
import FileUploadModal from "@/components/file/FileUploadModal.vue";
import {nextTick} from "vue";
import handleApiError from "@/shared/apiErrorUtil";
import FileDetailsModal from "@/components/file/FileDetailsModal.vue";
import Pagination from "@/components/interface/Pagination.vue";
import {processErrors} from "@/store/fileStore";

export default {
  name: "FileList",
  components: {Pagination, FileDetailsModal, ClickableIcon, FileUploadModal},
  props: {
    orgId: {
      String,
      default: "",
    },
    showExpiredFiles: {
      Boolean,
      default: true,
    },
  },
  data() {
    return {
      filter: null,
      filterOnColumns: ["name", "description", "mimeType", "expiryDate"],
      selectedFile: null,
      selectedFileId: null,
      pageSize: 15,
      page: 1,
      filteredFilesCount: 0,
      isDeleting: false,
      fields: [
        {
          key: "name",
          label: "File name",
          sortable: true,
        },
        {
          key: "description",
          label: "Description",
          sortable: true,
        },
        {
          key: "mimeType",
          label: "Type",
          sortable: true,
          formatter: this.mimeTypeFormatter,
          tdClass: "text-nowrap",
        },
        {
          key: "size",
          label: "Size",
          sortable: true,
          formatter: this.byteFormatter,
          tdClass: "text-nowrap",
        },
        {
          sortable: true,
          key: "expiryDate",
          label: "Expiry",
          formatter: this.expiryDateFormatter,
        },
        {
          key: "actions",
          label: "Actions",
          tdClass: "text-nowrap",
        },
      ],
    };
  },
  computed: {
    ...mapState("configStore", {
      maxOrgStorage: state =>
        state.fileStorageConfig ? state.fileStorageConfig.maxOrgStorage : 0,
    }),
    ...mapState("configStore", {
      maxUserStorage: state =>
        state.fileStorageConfig ? state.fileStorageConfig.maxUserStorage : 0,
    }),
    ...mapState("configStore", {
      maxFileSize: state =>
        state.fileStorageConfig ? state.fileStorageConfig.maxFileSize : 0,
    }),
    ...mapState("fileStore", {isLoading: state => state.isLoading}),
    ...mapState("fileStore", {
      isLoadingMimeTypes: state => state.isLoadingMimeTypes,
    }),
    ...mapState("fileStore", {files: state => state.files}),
    formattedMaxStorage() {
      if (this.isIndividualFiles) {
        return this.byteFormatter(this.maxUserStorage);
      } else {
        return this.byteFormatter(this.maxOrgStorage);
      }
    },
    showBusyState() {
      return this.isLoading || this.isLoadingMimeTypes;
    },
    isIndividualFiles() {
      return !this.orgId;
    },
    canShow() {
      return (
        !this.showBusyState &&
        (this.isIndividualFiles || this.isAdminOf()(this.orgId))
      );
    },
    formattedUsedStorage() {
      if (this.files !== undefined && this.files.length > 0) {
        return this.byteFormatter(
          this.files.map(item => item.size).reduce((prev, next) => prev + next)
        );
      } else {
        return this.byteFormatter(0);
      }
    },
    pageElements() {
      if (this.page * this.pageSize > this.filteredFilesCount) {
        return this.filteredFilesCount - (this.page - 1) * this.pageSize;
      } else {
        return this.pageSize;
      }
    },
  },
  mounted() {
    this.fetchFileStorageConfig().catch(error => {
      this.displayErrors(error);
    });
    this.fetchFiles(this.orgId)
      .then(() => this.onFiltered(this.files))
      .catch(error => {
        this.displayErrors(error);
      });
    this.fetchMimeTypes().catch(error => {
      this.displayErrors(error);
    });
  },
  methods: {
    ...mapGetters("auth", ["isAdminOf", "getCurrentUser"]),
    ...mapActions("configStore", ["fetchFileStorageConfig"]),
    ...mapGetters("fileStore", ["getMimeTypeByName"]),
    ...mapActions("fileStore", ["fetchFiles", "deleteFile", "fetchMimeTypes"]),
    byteFormatter(bytes) {
      return numeral(bytes).format("0.0 b");
    },
    expiryDateFormatter(expiryDate) {
      if (!expiryDate) {
        return {class: "no-date text-nowrap text-muted", text: "No expiry"};
      }
      const expiryDateMoment = moment(expiryDate, moment.ISO_8601);
      const formattedDate = expiryDateMoment.format("DD/MM/YYYY");
      // Compare the two dates while ignoring time component
      if (expiryDateMoment.isSameOrBefore(moment(), "day")) {
        return {class: "expired text-danger", text: formattedDate};
      } else {
        return {class: "", text: formattedDate};
      }
    },
    mimeTypeFormatter(mimeType) {
      const foundType = this.getMimeTypeByName()(mimeType);
      if (foundType) {
        return foundType.shortName;
      } else {
        return "Unknown";
      }
    },
    async showAddFileModal() {
      this.selectedFile = null;
      // It's important to wait for the DOM update before the showing dialog, since selectedFile data is passed to the child component using prop binding
      await nextTick();
      this.$bvModal.show("file-upload-modal");
    },
    async showEditFileModal(file) {
      this.selectedFile = file;
      // It's important to wait for the DOM update before the showing dialog, since selectedFile data is passed to the child component using prop binding
      await nextTick();
      this.$bvModal.show("file-upload-modal");
    },
    async viewFileDetails(file) {
      this.selectedFileId = file.id;
      await nextTick();
      this.$bvModal.show("fileDetails");
    },
    async showDeleteFileModal(file) {
      this.selectedFile = file;
      // It's important to wait for the DOM update before the showing dialog, since selectedFile data is passed to the child component using prop binding
      await nextTick();
      this.$bvModal.show("confirm-delete-modal");
    },
    handleDeleteFile() {
      this.isDeleting = true;
      this.deleteFile(this.selectedFile.id)
        .then(() => {
          this.$bvModal.hide("confirm-delete-modal");
        })
        .catch(error => this.displayErrors(error))
        .finally(() => (this.isDeleting = false));
    },
    displayErrors(error) {
      let errorList = processErrors(error);
      if (errorList.otherErrors.length > 0) {
        // Just display the first error in this case
        handleApiError(error, this, errorList.otherErrors[0]);
      } else {
        handleApiError(error, this, error.message);
      }
    },
    getUrl(fileId) {
      return "/api/v1/files/" + fileId + "/file";
    },
    onFiltered(filteredItems) {
      this.filteredFilesCount = filteredItems.length;
      this.page = 1;
    },
    updateCurrentPage(page) {
      this.page = page;
    },
  },
};
</script>

<style scoped>
.expired {
  font-weight: bold;
}
.no-date {
  font-style: italic;
}
#delete-button-overlay {
  cursor: not-allowed;
}
</style>
